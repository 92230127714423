<template>
  <p>
    В данный момент настройка, добавление и удаление ролей находится
    <a href="https://admin.alfagrad.net/" target="_blank">тут</a>.
  </p>
</template>

<script>
export default {
  name: 'profile-role'
};
</script>
